<template>
  <div>
    <!-- 内容块 -->
    <div class="detail-body" v-if="isLoaded">
      <!-- 标题 -->
      <div class="detail-title">{{data.title}}</div>

      <!-- 副标题 -->
      <div class="detail-subtitle flex-center-center">
        <div class="flex-center-center">
          <span class="iconfont icon-company"></span>
          <span class="company-name one-line">{{data.addedBy.companyName}}</span>
        </div>
      </div>

      <!-- 副标题 -->
      <div class="detail-subtitle flex-center-center">
        <div class="flex-center-center">
          <span class="iconfont icon-time"></span>
          <span>{{data.createdAt.substring(0, 10)}}</span>
        </div>
        <div class="flex-center-center">
          <span class="iconfont icon-read"></span>
          <span class="read-count one-line">{{data.readCount}}</span>
        </div>
      </div>

      <!-- 内容 -->
      <div class="detail-content">{{data.content}}</div>

      <!-- 联系人 -->
      <div class="contact">
        <div>联系人：{{data.username}}</div>
        <div>联系方式：{{data.phoneNumber}}</div>
      </div>

      <!-- 图片 -->
      <div class="image-list" v-viewer>
        <img :src="item" v-for="(item, index) in data.pictures" :key="index"/>
      </div>
    </div>

    <!-- 留言内容 -->
    <div class="comment">
      <div class="comment-title">相关留言</div>
      <!-- 添加留言 -->
      <div class="comment-add">
        <div class="comment-input">
          <textarea maxlength="300" v-model="formData.content" placeholder="添加留言" />
          <span class="word-count">{{formData.content.length}}/{{wordCount}}</span>
        </div>
        <div class="button-one" @click="handleAddComment">添加留言</div>
      </div>

      <!-- 留言列表 -->
      <div class="comment-box" v-for="(item, index) in list" :key="index" >
        <!-- 内容 -->
        <div class="flex-row-between-center">
          <div class="user-info flex-row-default-center">
            <img v-if="item.user && item.user.avatarUrl" :src="item.user.avatarUrl" />
            <img v-else src="@/assets/images/avatar.png"/>
            <span class="one-line">{{item.user.name}}</span>
          </div>
          <span class="comment-time">{{item.createdAt.substring(0, 10)}}</span>
        </div>
        <div class="comment-content">{{item.content}}</div>
        <div v-if="canDelete || item.userID === userInfo.userID" class="delete-btn" @click="handleDelete(item.commentID)">删除</div>
      </div>

      <!-- 底标 -->
      <div class="no-more" v-if="isEnd">没有更多内容了</div>
    </div>
  </div>
</template>

<script>
import loading from '@/mixins/loading'
import page from '@/mixins/page'
import { MessageBox, Toast } from 'mint-ui'

export default {
  name: 'ExchangeInfoDetail',

  data () {
    return {
      id: '',
      data: {
        addedBy: {}
      },
      userInfo: {},
      wordCount: 300,
      formData: {
        content: ''
      },
      list: [],
      currentPage: 1,
      pageSize: 8,
      isEnd: false
    }
  },

  computed: {
    canDelete () {
      return this.data.addedBy.userID === this.userInfo.userID
    }
  },

  mixins: [loading, page],

  beforeCreate () {
    const images = [
      require('@/assets/images/ActivityInfo.png'),
      require('@/assets/images/ProjectCooperation.png'),
      require('@/assets/images/Recruitment.png'),
      require('@/assets/images/Other.png')
    ]
    for (const src of images) {
      const image = new Image()
      image.src = src
    }
  },

  created () {
    this.userInfo = this.$store.state.userInfo || {}
    this.showLoading()
    this.id = this.$route.query.id
    this.getData()
  },

  beforeRouteLeave (to, from, next) {
    to.meta.keepAlive = (to.path === '/member-communication' || to.path === '/user/member-exchange-info-list')
    next()
  },

  methods: {
    // 获取信息
    getData () {
      this.api.getAllMemberExchangeInfoByID({ id: this.id }).then(res => {
        this.data = res.data
        this.getAllComment()
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.getData() })
      }).finally(() => {
        this.hideLoading()
      })
    },
    // 获取留言
    getAllComment (isLazy = false) {
      if (isLazy) {
        this.currentPage = 1
        this.isEnd = false
      }
      this.api.getAllCommentByInfoExchangeID({ id: this.id, currentPage: this.currentPage, pageSize: this.pageSize }).then(res => {
        if (res.data.length === 0 || res.data.length < this.pageSize) this.isEnd = true
        if (isLazy) {
          this.list = res.data
        } else {
          this.list = [...this.list, ...res.data]
        }
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.getAllComment() })
      }).finally(() => {
        this.hideLoading()
      })
    },
    // 添加留言
    handleAddComment () {
      if (this.formData.content === '') {
        Toast('留言内容不能为空')
        return
      }
      this.api.addComment({ id: this.id, content: this.formData.content }).then(res => {
        this.getAllComment(true)
        this.formData.content = ''
        Toast(res.msg)
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.handleAddComment() })
      })
    },
    // 触底函数
    hitBottom () {
      if (this.isEnd) return
      this.currentPage++
      this.getAllComment()
    },
    // 删除留言
    handleDelete (id) {
      MessageBox.confirm('确认删除该留言？').then(() => {
        this.handleConfirmDelete(id)
      }).catch(() => {})
    },
    // 确认删除留言
    handleConfirmDelete (id) {
      this.api.delComment({ id }).then(res => {
        Toast(res.msg || '删除成功')
        this.initPage()
        this.getAllComment()
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.handleConfirmDelete() })
      })
    }
  }
}
</script>

<style scoped>
.icon-time, .icon-read {
  margin: 0 3px 0 10px;
}
.company-name {
  max-width: calc(100vw - 40px - 50px);
}
.read-count {
  max-width: calc(100vw - 40px - 100px - 50px);
}

.detail-content {
  color: var(--light-font-color);
  word-break: break-all;
  white-space: pre-wrap;
  line-height: 32px;
  font-size: 14px;
}
.contact {
  padding: 10px 0;
  font-size: 14px;
  line-height: 24px;
  word-break: break-all;
}

.comment {
  margin-top: 15px;
}

.comment-title {
  width: 100%;
  margin-bottom: 1px;
  padding: 10px 0;
  text-align: center;
  color: var(--main-color);
  background: #fff;
}

.comment-add {
  width: 100%;
  margin-bottom: 8px;
  padding: 10px;
  background: #fff;
}
.comment-input {
  position: relative;
}
.comment-input > textarea {
  width: 100%;
  min-height: 120px;
  color: var(--normal-font-color);
  background: #f7f7f7;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 14px;
  padding: 10px;
  margin: 3px 0;
}
.button-one {
  margin-top: 0;
  border-radius: 2px;
}

.comment-box {
  width: 100%;
  padding: 20px;
  margin-bottom: 8px;
  border-radius: 5px;
  box-shadow: 2px 2px 2px #eeeeee;
  background: #fff;
}
.user-info > img {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  border-radius: 50%;
  box-shadow: 2px 2px 2px #eeeeee;
}
.user-info > span {
  font-size: 16px;
  width: calc(100vw - 40px - 15px - 120px);
}
.comment-time {
  font-size: 13px;
  color: #666666;
}
.comment-content {
  margin-top: 15px;
  padding: 0 5px;
  color: var(--light-font-color);
}

.delete-btn {
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  color: var(--main-color);
  margin: 5px;
}

.no-more {
  margin-top: 15px;
}
</style>
