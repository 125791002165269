// 混入必须要实现hitBottom方法
export default {
  created () {
    window.addEventListener('scroll', this.scroll)
  },

  beforeDestroy () {
    window.removeEventListener('scroll', this.scroll)
  },

  methods: {
    // 文档高度
    getDocumentTop () {
      return document.documentElement.offsetHeight
    },

    // 可视窗口高度
    getWindowHeight () {
      return document.documentElement.clientHeight
    },

    // 滚动条滚动高度
    getScrollHeight () {
      return Math.max(document.documentElement.scrollTop, window.pageYOffset || 0)
    },

    // 触底函数
    scroll () {
      if ((this.getScrollHeight() + this.getWindowHeight()) >= this.getDocumentTop()) {
        if (typeof (this.hitBottom) !== 'function') {
          console.log('[!] must implement the function of hitBottom')
          return
        }
        this.hitBottom()
      }
    },

    // 切换分类重置相关数据
    initPage () {
      this.isEnd = false
      this.currentPage = 1
      this.list = []
    }
  }
}
